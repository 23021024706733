.News {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1%;
  width: 80%;
  background-color: white;
  gap: 20px;
}

.News img {
  width: 100%;
  height: 25vh;
}

.pagination {
  width: 30%;
  height: 5vh;
  display: flex;
  justify-content: center;
  gap: 1%;
  margin: 3% 0;
}

.search-bar {
  display: flex;
  margin-bottom: 2%;
  margin-top: 5%;

  width: 80%;
  background-color: white;
  border: 1px solid #ccc;
}

.search-bar input {
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
}

.search-bar button {
  padding: 8px;
  font-size: 16px;
  background-color: #ffa700;
  color: #fff;
  border: none;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
}

.p {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.date-p {
  font-size: 13px;
  margin-bottom: 1%;
  font-family: 'Lato', sans-serif;

  color: #333;
  margin-bottom: 2%;
}

.headline-h2 {
  color: #ffa700;
  cursor: pointer;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5%;
}

.description-h3 {
  color: #666666;
  font-size: 1.2em;
  font-family: 'Lato', sans-serif;
}

.box {
  width: 32%;
  margin-bottom: 5%;
}

.pagination button {
  padding: 3% 2%;
  background-color: #666666;
}

.pagination button:hover {
  background-color: #666666;
}

@media screen and (max-width: 1248px) {
  .News {
    margin-top: 1%;
    width: 80%;
    background-color: white;
    gap: 19px;
  }
}

@media screen and (max-width: 1200px) {
  .News {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1%;
    width: 80%;
    background-color: white;
    gap: 8px;
  }
}

@media screen and (max-width: 1450px) {
  .News img {
    width: 100%;
    height: 20vh;
  }
}

@media screen and (max-width: 768px) {
  .box {
    width: 48%;
  }
}

@media screen and (max-width: 995px) {
  .headline-h2 {
    font-size: 15px;
  }

  .description-h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 500px) {
  .News {
    display: flex;
    flex-direction: column;
  }

  .box {
    width: 100%;
  }
}

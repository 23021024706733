.footer_main_div {
  background-color: #1f2833;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_content {
  width: 30%;
}

.tiktok_icon {
  width: 25px;
  height: 25px;
}

.footer_subdiv {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 5% 0;
}

.footer_row_content h1 {
  color: white;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  margin: 8% 0;
}

.bottom_line {
  border-top: solid 1px #007aff;
}

.footer_content {
  color: white;
}

.footer_address {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 6% 0;
}

.footer_address p {
  width: 90%;
  font-size: 22px;
  font-weight: 400;
  line-height: 23px;
}

.social_icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
}

.footer_ancor {
  font-size: 22px;
  font-family: sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.footer_ancor li a {
  color: white;
  text-decoration: none;
}

.footer_ancor li {
  color: white;
  margin: 3% 0;
  list-style: none;
  cursor: pointer;
}

.footer_address_disclamer p {
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  font-family: sans-serif;
  margin: 5% 0;
}

.projects_name p {
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  font-family: sans-serif;
  margin: 6% 0;
}

.projects_name {
  width: 45%;
}

.projects-main_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .footer_subdiv {
    width: 90%;
  }
}

@media screen and (max-width: 1370px) {
  .project_name_res {
    /* background-color: red; */
  }

  .project_name_res p {
    margin-top: 0;
    margin-bottom: 6%;
  }

  .projects-main_div {
    flex-direction: column;
  }

  .projects_name {
    width: 100%;
  }

  .footer_row_content h1 {
    font-size: 18px;
  }

  .footer_address p {
    font-size: 16px;
  }

  .social_icons {
    width: 85%;
  }

  .footer_ancor li {
    font-size: 16px;
  }

  .footer_address_disclamer p {
    font-size: 16px;
  }

  .projects_name p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .project_name_res p {
    margin-top: 0 !important;
    /* margin-bottom: 6% !important; */
  }

  .footer_subdiv {
    flex-direction: column;
  }

  .footer_content {
    width: 100%;
  }

  .footer_row_content h1 {
    margin: 2% 0;
  }

  .footer_address {
    margin: 3% 0;
    justify-content: flex-start;
    gap: 2%;
  }

  .social_icons {
    width: 33%;
  }

  .footer_ancor li {
    margin: 2% 0;
  }

  .footer_address_disclamer p {
    margin: 1%0;
  }

  .projects_name p {
    margin: 2% 0;
  }
}

/* Importing all CSS files */

/* Defaults */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Primary Variables */
:root {
  --primary: 'green';
  font-family: 'Lato', sans-serif;
}

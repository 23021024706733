.choose__video {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.video-container {
  margin-bottom: 20px;
  text-align: center;
  width: 30%;
}

.video-container p {
  font-size: 20px;
  color: #333;
  margin: 3% 0;
}

.main_videos_div {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.video-top {
  margin-top: 5%;
  margin-bottom: 3%;
  width: 80%;
  height: 60vh;
}
.unActive {
  background-color: white;
}
.play-videos {
  height: 26vh !important;
  width: 100% !important;
}
.react_player {
  width: 100% !important;
  height: 54vh !important;
}

@media screen and (max-width: 1366px) {
  .react_player {
    height: 92% !important;
    width: 100% !important;
  }
  .video-top {
    height: 60vh;
  }
}
@media screen and (max-width: 1024px) {
  .video-container {
    width: 47%;
  }
  .video-container p {
    font-size: 16px;
  }
  .video-top {
    height: 50vh;
  }
}
@media screen and (max-width: 768px) {
  .choose__video {
    flex-direction: column;
    width: 80%;
  }
  .video-container {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .video-top {
    height: 30vh;
    width: 90%;
  }
  .video-top {
    height: 20vh;
  }
  .video-container {
    width: 100%;
  }
  .play-videos {
    height: 21vh !important;
  }
}

.button-container {
  position: fixed;
  right: 2%;
  top: 78%;
  background: #2db742;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.button-container button {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 25px;
  background: #2db742;
}

.chat-box {
  position: fixed;
  right: 3%;
  top: 76%;
  background: #ffff;
  color: #000000;
  width: 20%;
  height: 100px;
  padding: 0 3%;
  z-index: 5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-link {
  width: 100%;
  text-decoration: none;
  height: 60%;
  color: black;
  border-left: 2px solid green;
  background: #f5f7f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-box-text {
  cursor: pointer;
  position: fixed;
  right: 5%;
  top: 78%;
  background: #f5f7f9;
  color: #000000;
  width: fit-content;
  height: 50px;
  padding: 0 1%;
  z-index: 5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1500px) {
  .chat-box-text {
    right: 6%;
  }
  .chat-box {
    right: 4%;
    width: 26%;
  }
}
@media screen and (max-width: 1024px) {
  .chat-box-text {
    right: 7.3%;
  }
}
@media screen and (max-width: 768px) {
  .chat-box-text {
    right: 9.3%;
  }
  .chat-box {
    right: 6%;
    width: 40%;
    top: 75%;
  }
}
@media screen and (max-width: 600px) {
  .chat-box-text {
    display: none;
  }
  .chat-box {
    right: 9%;
    width: 60%;
    top: 75%;
  }
}
@media screen and (max-width: 400px) {
  .chat-box {
    font-size: 14px;
    right: 12%;
  }
}

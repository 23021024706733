.navbar_main_div {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
}
.navbar_subdiv {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar_logo_div {
  width: 20%;
}
.navbar_logo_div img {
  width: 100%;
}
.topnav a {
  color: white;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 32.4px;
  margin-top: 6%;
}
.topnav a:hover {
  color: #808080 !important;
  transition: 0.3s;
}
.topnav {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 5%;
  align-items: center;
}
.active {
  color: #ffa700 !important;
  text-decoration: none;
}
.active-link {
  color: white !important;
  text-decoration: none;
  padding: 2%;
}
.menu_mobile {
  display: none;
}
.topnav_mobile {
  display: none;
}
@media screen and (max-width: 1500px) {
  .navbar_subdiv {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .topnav {
    display: none;
  }
  .navbar_main_div {
    width: 100%;
  }
  .menu_mobile {
    display: block;
    color: white;
    background-color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7% 0;
    z-index: 11;
  }
  .topnav_mobile {
    position: absolute;
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: black;
    top: 110px;
    z-index: 99;
    left: 0;
  }
  .navbar_subdiv {
    width: 100%;
    flex-direction: column;
    height: 100px;
  }
}

.contact-container {
  width: 65%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.88);
}
.main-section_Contact {
  background-image: url(../../assets/contactbg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.Contact-Us {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 20px;
  text-align: center;
}
.Contact-Us img {
  width: 100%;
  position: relative;
  padding: 5%;
}
.bottom_line_contact {
  border-top: solid 1px black;
  width: 90%;
  margin: 2% 0;
}
.head-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}
.head-content h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Poppins', sans-serif;
  color: rgb(18, 55, 99);
  padding-top: 10%;
}
.head_address {
  display: flex;
  gap: 1%;
  width: 80%;
  margin: 6% 0;
  font-family: 'Lato', sans-serif;
}
.head_address p {
  color: black;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}
.head-office {
  background-color: #ffffff;
  color: #fff;
  width: 35%;
  display: flex;
  font-family: 'Lato', sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
}

.form_btn {
  background-color: black;
  border: solid 1px #333;
  padding: 2% 0;
  margin: 3% 0;
  font-size: 18px;
  color: white;
  cursor: pointer;
}

.Section {
  display: flex;
  justify-content: space-between;
}
.Section input {
  margin-bottom: 5%;
  width: 48%;
  padding: 2% 1%;
  font-size: 18px;
  background-color: black;
  color: white;
  border: solid 1px #333;
  outline: none;
}
.contact {
  display: flex;
  justify-content: space-between;
}
.contact input {
  margin-bottom: 5%;
  width: 100%;
  padding: 8px;
}
.main_section_sub_div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 8% 0;
}
.contact_form_left_side {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_left_icons {
  background-color: pink;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 20%;
  padding: 5% 0;
}
.contact-container h2 {
  color: white;
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
  font-family: 'Poppins', sans-serif;

  padding: 5%0;
}
.form_textarea {
  font-size: 18px;
  color: white;
  background-color: black;
  border: solid 1px #333;
  outline: none;
  padding: 1%;
}

@media screen and (max-width: 1200px) {
  .head-office {
    width: 40%;
  }
}
@media screen and (max-width: 1024px) {
  .head-office {
    width: 100%;
  }
  .main_section_sub_div {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact-container {
    width: 100%;
  }
}
@media screen and (max-width: 526px) {
  .main_section_sub_div {
    width: 100%;
  }
  .Section input {
    font-size: 12px;
  }
  .contact-container h2 {
    font-size: 35px;
  }
  .form_textarea {
    font-size: 12px;
  }
}

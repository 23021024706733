.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header_img_1_projects {
  width: 30%;
  cursor: pointer;
  margin-top: 4%;
}

.header_img_1_projects button {
  background-color: #ffa700;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  text-align: center;
  margin-top: -5px;
  padding: 4% 0;
  width: 100%;
  border: none;
  outline: none;
}

.header_img_1_projects button {
  background-color: #ffa700;
}

.projects_list {
  display: flex;
  justify-content: flex-start;
  margin: 5% 0;
  align-items: center;
  z-index: 2;
  flex-wrap: wrap;
  width: 100%;
  gap: 5%;
}

.header_img_1_projects img {
  width: 100%;
  height: 25vh;
}

.main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.main-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.main-section h1 {
  text-align: center;
  font-size: 29px;
  color: white;
  width: 100%;
  font-weight: 800;
  margin-top: 15%;
}

.main-section1 h1 {
  text-align: center;
  font-size: 29px;
  color: white;
  width: 100%;
  font-weight: 800;
  margin-top: 5%;
}

.projectsbg {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.95) 100%),
    url('../../assets/images/projectsbg.jpg');
  background-repeat: no-repeat;
  background-position: cover;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1500px) {
  .main-section h1 {
    margin-top: 25%;
  }

  .main-section1 h1 {
    font-size: 22px;
  }
}

@media screen and (max-width: 768px) {
  .header_img_1_projects {
    width: 47%;
  }

  .main-section h1 {
    margin-top: 30%;
  }

  .header_img_1_projects {
    margin: 5% 0%;
  }
}

@media screen and (max-width: 500px) {
  .header_img_1_projects {
    width: 100%;
  }
}

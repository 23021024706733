@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;700;900&display=swap');

.background-container {
  background-image: url('../../assets/SM-BG.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.background-opacity {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.background-opacity_2 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
}

.header_heading {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  animation: fadeIn 5s;
}

.header_heading h1 {
  font-size: 90px;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  color: #123763;
}

.header_heading p {
  font-size: 42px;
  color: white;
}

.header_imgs_main_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10%;
}

.header_imgs_main_div img {
  width: 100%;
}

.header_img_1 {
  width: 48%;
}

.header_img_1 P {
  background-color: #ffa700;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: -3px;
}

.title_main_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title_sub_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3% 0;
}

.title_div {
  font-size: 29px;
  font-weight: 700;
  width: 50%;
  color: rgb(255, 167, 0);
  padding: 4% 7%;
  font-family: 'Poppins', sans-serif;

  display: flex;
  justify-content: center;
  background: #efefef;
  line-height: 44px;
}

.gurrente_imgs_div {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  gap: 16%;
}

.gurrente_imgs_div img {
  width: 100%;
}

.hero_section_main_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 3% 0;
}

.hero_section_sub_div {
  width: 80%;
}

.hero_sectio_content_div {
  display: flex;
  justify-content: space-between;
}

.hero_sectio_content_div p {
  color: black;
  font-size: 20px;
  width: 50%;
  line-height: 25px;
  font-weight: 400;
  font-family: sans-serif;
  width: 80%;
}

.herosecimg_div {
  width: 50%;
}

.herosecimg_div img {
  width: 100%;
}

.hero_section_para {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.hero_section_bottom_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.5%;
}

.hero_section_bottom_sub_div {
  background-color: #e9eaec;
  width: 60%;
}

.background-image_2 {
  background-image: url('../../assets/home-1920x1080-1.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bottom_content_img {
  width: 50%;
  position: absolute;
  top: -15%;
  z-index: 1;
  right: 57%;
}

.bottom_content_img img {
  width: 100%;
}

.bottom_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom_content_text {
  align-items: center;
  width: 60%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 2;
}

.bottom_content_text h1 {
  font-size: 54px;
  font-weight: 600;
  line-height: 59px;
  color: black;
  font-family: sans-serif;
}

.text-btm_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 3% 0;
}

.contact_btn_homepage {
  display: flex;
  justify-content: center;
  width: 30%;
  padding: 3% 1%;
  border-radius: 8px;
  background-color: #ffa700;
  color: white;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 600;
  border: none;
  text-decoration: none;
}

.btn_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects_main_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Vimg {
  position: absolute;
  width: 100%;
  top: -0.1%;
  z-index: 2;
}

.choose_us_main_div {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8%;
  position: relative;
}

.choose_sub_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  z-index: 2;
}

.choose_sub_div {
  font-size: 22px;
  font-weight: 800;
  line-height: 29px;
  font-family: sans-serif;
  text-align: center;
  padding: 3% 0;
}

.choose_btm_line {
  border-top: solid 10px orange;
  margin: 3% 0;
  z-index: 3;
}

.choose_heading {
  width: 75%;
  z-index: 1;
}

.choose_heading h1 {
  font-size: 41px;
}

.choose_bg_div {
  background-image: url('../../assets/home-1920x1080-1.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
}

.background-opacity_4 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 90%);
  z-index: 1;
}

.choose_opptions_main_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.choose_opption_left_div {
  width: 50%;
  display: flex;
  /* justify-content: center; */
  /* background-color: red; */
}

.opption_1 {
  width: 80%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5%;
  margin: 4.5% 25%;
}

.opption_1 h1 {
  font-size: 20px;
  color: #123763;
  font-weight: 700;
  line-height: 30px;
  font-family: sans-serif;
}

.reviews_main_div {
  width: 50%;
  display: flex;
  align-items: center;

  flex-direction: column;
}

.review_1 {
  background-color: #0c71c3;
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  padding: 5% 0;
  position: relative;
  margin: 3% 0;
}

.user_img_div {
  width: 15%;
}

.user_img_div img {
  width: 100%;
  border-radius: 50%;
}

.review_para {
  width: 70%;
}

.review_para p {
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: sans-serif;
  line-height: 24px;
}

.icons {
  background-color: 'black';
  width: '10%';
}

.review_para h4 {
  display: flex;
  align-items: flex-start;
  margin: 3% 0;
  font-size: 18px;
  color: #e9eaec;
  font-weight: 600;
  font-family: sans-serif;
}

.opptions_icons {
  display: flex;
  justify-content: flex-start;
}

.opptions_main_div_left {
  width: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
}

.clints_rating_div {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clints_rating_div h1 {
  font-size: 29px;
  font-weight: 700;
  line-height: 29px;
  font-family: 'Poppins', sans-serif;

  color: rgb(30, 61, 126);
}

.clints_rating_div h4 {
  font-size: 26px;
  font-weight: 400;
  line-height: 32.4px;
  color: black;
  margin: 2% 0;
}

.rating_img_div {
  width: 80%;
}

.rating_img_div img {
  width: 100%;
}

.clints_rating_div button {
  background-color: #1e3d7e;
  padding: 3%;
  color: white;
  margin-top: 2%;
  outline: none;
  border: none;
  font-size: 18px;
}

.Contact_background {
  background-image: url('../../assets/home-1920x1080-1.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-opacity_5 {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(255, 167, 0, 0.85) 0%,
    rgba(30, 61, 126, 0.85) 100%
  );
  z-index: 1;
}

.contact_us_home_page {
  width: 100%;
}

.contact_home_page_inner {
  width: 80%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  margin: 9% 0;
}

.contact_home_heading_main_div {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact_home_from {
  width: 40%;
  display: flex;
  justify-content: center;
}

.contact_home_heading_sub_div {
  width: 90%;
}

.contact_home_heading_sub_div h1 {
  font-size: 29px;
  font-weight: 700;
  line-height: 29px;
  color: rgb(255, 255, 255);
  font-family: 'Poppins', sans-serif;
}

.contact_btm_line {
  border-top: solid 2px white;
  margin: 4% 0;
}

.contact_home_heading_sub_div h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: rgb(255, 255, 255);
  font-family: 'Poppins', sans-serif;
}

.contact_home_heading_sub_div p {
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
  color: rgb(255, 255, 255);
  margin-top: 3%;
  font-family: 'Poppins', sans-serif;
}

.form_here_home_page {
  background-color: black;
  color: white;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;

  border-radius: 3%;
  padding: 8% 0;
}

.mna_p_span {
  font-weight: 800;
}

.form_here_home_page h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  margin: 3% 0;
}

.form_here_home_page span {
  color: rgb(255, 167, 0);
}

.forms_fields_home_page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forms_fields_home_page input {
  width: 90%;
  margin: 2% 0;
  border: none;
  outline: none;
  padding: 1%;
  background-color: black;
  color: #999;
  font-family: sans-serif;
}

.forms_fields_home_page ::placeholder {
  color: #999;
}

.input_btm_line {
  border-top: solid 2px #ffa700;
  width: 86%;
  margin: 2% 0;
}

.forms_fields_home_page textarea {
  width: 86%;
  background-color: black;
  color: #999;
  border: none;
  outline: none;
}

.forms_fields_home_page button {
  width: 86%;
  padding: 2%;
  background-color: #ffa700;
  color: white;
  font-family: sans-serif;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 6px;
  margin: 3% 0;
}

.call_now_img {
  position: absolute;
  z-index: 6;
  top: 12.2%;
  background-color: red;
  left: 15%;
  width: 5%;
  height: 200px;
  background: transparent;
  background-repeat: no-repeat;
  transform-origin: 50% 0;
  animation: moveIt 5.5s ease-in-out infinite;
  background-image: url('../../assets/call-now.png');
  background-size: contain;
}

.call_now_img img {
  width: 100%;
}

@keyframes moveIt {
  0%,
  100% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(-15deg);
  }
}

.scrolling_main_div {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  z-index: 7;
  padding: 1%;
}

.scrolling-text {
  white-space: nowrap;

  overflow: hidden;
  animation: scrolling 50s linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.scrolling_main_div_btn {
  width: 5%;
  font-size: 22px;
  font-family: sans-serif;
}

.scrolling_main_div_btn button {
  position: fixed;
  width: 6.5%;
  bottom: 0%;
  z-index: 9;
  right: 0;
  padding: 0;
  margin: 0;
  background-color: black;
}

.disclaimer_btn {
  cursor: pointer;
  width: 100%;
}

.disclaimer_btn img {
  width: 100%;
}

.quote_img_main_div img {
  width: 40%;
}

.quote_img_main_div {
  width: 13%;
  position: absolute;
  z-index: 5;
  top: -15px;
}

@media screen and (max-width: 1800px) {
  .background-image_2 {
    height: 250px;
  }

  .bottom_content_text h1 {
    font-size: 40px;
  }

  .header_heading {
    width: 56%;
  }

  .header_heading p {
    font-size: 39px;
  }

  .header_heading h1 {
    font-size: 80px;
  }

  .header_imgs_main_div {
    width: 80%;
  }

  .header_img_1 p {
    font-size: 24px;
  }

  .call_now_img {
    top: 12%;
    width: 6%;
  }
}

@media screen and (max-width: 1500px) {
  .header_heading {
    width: 64%;
  }

  .header_imgs_main_div {
    width: 70%;
    margin-top: 7%;
  }

  .header_img_1 p {
    font-size: 20px;
  }

  .call_now_img {
    left: 5%;
  }

  .hero_sectio_content_div p {
    width: 100%;
  }

  .herosecimg_div {
    width: 44%;
  }

  .hero_section_bottom_sub_div {
    width: 68%;
  }

  .projects_main_div {
    margin-top: 5%;
  }

  .choose_heading h1 {
    font-size: 30px;
  }

  .opptions_main_div_left {
    width: 90%;
  }

  .contact_home_heading_sub_div h1 {
    font-size: 22px;
  }

  .contact_home_heading_sub_div p {
    font-size: 40px;
  }
}

@media screen and (max-width: 1370px) {
  .background-image_2 {
    height: 225px;
  }

  .contact_btn_homepage {
    padding: 2% 0;
  }

  .bottom_content_text h1 {
    font-size: 36px;
  }

  .opptions_icons {
    width: 15%;
  }

  .choose_sub_div {
    width: 90%;
  }

  .call_now_img {
    top: 16%;
    width: 7%;
  }

  .header_heading {
    width: 90%;
  }

  .header_imgs_main_div {
    width: 50%;
    margin-top: 4%;
  }

  .header_heading p {
    font-size: 29px;
  }

  .header_heading h1 {
    font-size: 65px;
  }

  .header_img_1 p {
    font-size: 18px !important;
  }

  .title_div {
    padding: 4% 4%;
  }

  .hero_sectio_content_div p {
    font-size: 16px;
  }

  .bottom_content_img {
    right: 62%;
    width: 43%;
  }

  .hero_section_bottom_sub_div {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) {
  .contact_home_page_inner {
    width: 90%;
  }

  .contact_home_heading_sub_div {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .opption_1 {
    margin: 4.5% 15%;
  }

  .contact_home_from {
    width: 60%;
    margin-top: 4%;
  }

  .topnav_mobile {
    top: 113px;
  }

  .contact_home_page_inner {
    align-items: center;
  }

  .contact_home_heading_main_div {
    width: 100%;
  }

  .contact_home_page_inner {
    flex-direction: column;
  }

  .form_here_home_page h1 {
    font-size: 16px;
  }

  .herosecimg_div {
    width: 90%;
    margin: 3% 0;
  }

  .hero_section_para {
    width: 100%;
  }

  .hero_sectio_content_div {
    flex-direction: column;
    align-items: center;
  }

  .reviews_main_div {
    width: 100%;
  }

  .choose_opptions_main_div {
    flex-direction: column;
  }

  .choose_opption_left_div {
    width: 100%;
  }

  .call_now_img {
    top: 120px;
    width: 9%;
  }

  .gurrente_imgs_div {
    display: none;
  }

  .title_div {
    width: 100%;
    justify-content: flex-start;
    font-size: 25px;
  }

  .background-image_2 {
    height: 170px;
  }

  .bottom_content_text h1 {
    font-size: 30px;
  }

  .contact_btn_homepage {
    width: 26%;
    padding: 2% 1%;
    font-size: 14px;
  }

  .bottom_content_img {
    top: -11%;
  }

  .header_imgs_main_div {
    flex-direction: column;
  }

  .header_img_1 {
    margin: 3% 0;
  }

  .header_imgs_main_div {
    width: 60%;
  }
}

@media screen and (max-width: 900px) {
  .topnav_mobile {
    top: 98px;
  }
}

@media screen and (max-width: 768px) {
  .background-image_2 {
    height: 131px;
  }

  .header_heading h1 {
    font-size: 45px;
  }

  .header_heading p {
    font-size: 24px;
  }

  .header_img_1 p {
    font-size: 12px !important;
  }

  .title_div {
    font-size: 22px;
  }

  .contact_btn_homepage {
    width: 34%;
  }

  .bottom_content_text h1 {
    font-size: 22px;
  }

  .text-btm_img img {
    width: 100%;
  }

  .text-btm_img {
    width: 59%;
    margin: 0;
  }

  .choose_heading h1 {
    font-size: 20px;
  }

  .opption_1 h1 {
    font-size: 17px;
  }

  .review_para p {
    font-size: 14px;
  }

  .review_para h4 {
    font-size: 16px;
  }

  .form_here_home_page {
    width: 90%;
  }

  .form_here_home_page h1 {
    font-size: 15px;
  }

  .contact_home_heading_sub_div h1 {
    font-size: 16px;
  }

  .contact_home_heading_sub_div p {
    font-size: 30px;
  }

  .contact_home_heading_sub_div h2 {
    font-size: 20px;
  }

  .footer_row_content h1 {
    font-size: 16px;
  }

  .footer_address p {
    font-size: 14px;
  }

  .footer_ancor p {
    font-size: 14px;
  }

  .footer_address_disclamer p {
    font-size: 14px;
    line-height: 24px;
  }

  .projects_name p {
    font-size: 14px;
    line-height: 24px;
  }

  .call_now_img {
    width: 8%;
  }

  .scrolling_main_div {
    padding: 0;
  }

  .scrolling-text {
    font-size: 18px;
  }

  .scrolling_main_div_btn button {
    width: 4.5%;
  }

  .topnav_mobile {
    top: 100px;
  }
}

@media screen and (max-width: 500px) {
  .call_now_img {
    top: 100px;
  }

  .header_heading h1 {
    font-size: 35px;
  }

  .header_heading p {
    font-size: 16px;
  }

  .header_imgs_main_div {
    width: 80%;
  }

  .title_div {
    line-height: 24px;
    font-size: 16px;
  }

  .hero_sectio_content_div p {
    line-height: 24px;
  }

  .bottom_content_text h1 {
    font-size: 16px;
    line-height: 22px;
  }

  .contact_btn_homepage {
    width: 50%;
  }

  .text-btm_img {
    margin: 6px;
  }

  .Vimg {
    height: 9vh;
  }

  .main-section h1 {
    font-size: 22px;
  }

  .choose_heading h1 {
    font-size: 16px;
  }

  .contact_home_from {
    width: 90%;
  }

  .social_icons {
    width: 60%;
  }

  .background-image_2 {
    height: 86px;
  }

  .bottom_content_text {
    width: 70%;
  }

  .navbar_logo_div {
    width: 30%;
  }

  .topnav_mobile {
    top: 95px;
  }

  .scrolling_main_div_btn button {
    width: 6.5%;
  }
}

@media screen and (max-width: 400px) {
  .navbar_logo_div {
    width: 40%;
  }

  .bottom_content_img {
    right: 57%;
    width: 53%;
  }

  .clints_rating_div h1 {
    font-size: 18px;
  }

  .clints_rating_div h4 {
    font-size: 18px;
    line-height: 12px;
  }

  .opptions_main_div_left {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .opption_1 h1 {
    font-size: 13px;
  }

  .choose_heading {
    width: 85%;
  }

  .choose_btm_line {
    border-top: solid 4px orange;
  }

  .choose_heading h1 {
    font-size: 14px;
  }

  .contact_home_heading_sub_div h1 {
    font-size: 15px;
  }
}
